import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../utils/seo"

function Contacte() {
  const [enviat, setEnviat] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  // const handleSubmit = e => {
  //   e.preventDefault()
  //   setEnviat(true)
  //   setMessage('')
  // }
  useEffect(() => {
    setTimeout(() => setEnviat(false), 5000)
  }, [enviat])

  return (
    <Layout title="Contacta amb nosaltres">
      <SEO title="Contacte" />
      <div className="container">
      <div className="row" style={{ margin: "20px auto", maxWidth: 800 }}>
        <div className="col2 column">
          <h4>Tens qualsevol dubte?</h4>
          <br />
          <p>Doncs no ho dubtis i contacta amb nosaltres</p>
        </div>
        <div className="col2 column">
          <form
            name="contact"
            method="POST"
            // netlify-honeypot="bot-field"
            data-netlify="true"
            // onSubmit={handleSubmit}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>
                Email:{" "}
                <input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="text"
                  name="email"
                />
              </label>
            </p>
            <p>
              <label>
                Nom:{" "}
                <input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type="text"
                  name="name"
                />
              </label>
            </p>
            <p>
              <label>
                Telèfon:{" "}
                <input
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  type="text"
                  name="phone"
                />
              </label>
            </p>
            <p>
              <label>
                Missatge:{" "}
                <textarea
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  name="message"
                ></textarea>
              </label>
            </p>
            <div data-netlify-recaptcha="true"></div>
            {enviat ? (
              <p style={{ color: "#89ce40" }}>
                El teu missatge s'ha enviat correctament!
              </p>
            ) : (
              <p>
                <button disabled={!message || !email || !name || !phone} type="submit">
                  Enviar
                </button>
              </p>
            )}
          </form>
        </div>
      </div>
      </div>
    </Layout>
  )
}

export default Contacte
